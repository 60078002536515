<template>
  <div>
    <div class="row">
      <div class="col-lg-3  col-sm-12 mb-3" >
        <v-card :style="{ height: '150px', backgroundColor: '#03B397' }"  >
          <v-card-title>

          <v-row> 
              <div class="col-lg-2  " >
                <v-icon large class="text-light">mdi-account-multiple</v-icon>

              </div>
              <div class="col-lg-9  " >
                <span class="ml-3 text-light  font-poppins">Total Customers</span>
              <br />
              <span class="font-weight-bold pl-4 text-light">{{ totalCustomers }}</span>
                </div>

            </v-row>
            </v-card-title>
            <!-- <div class="d-flex justify-content-between align-items-center">
              <v-icon large class="text-light">mdi-account-multiple</v-icon>
              <span class="ml-3 text-light font-poppins ">Total Customers</span>
              <span class="font-weight-bold text-light pl-4">{{ totalCustomers }}</span>
            </div> -->
        </v-card>
      </div>
      <div class="col-lg-3  col-sm-12 mb-3" >
        <v-card :style="{ height: '150px', backgroundColor: '#DC3646' }"> 
          <v-card-title>
            <v-row> 
              <div class="col-lg-2  " >
                <v-icon large class="text-light">mdi-shopping</v-icon>

              </div>
              <div class="col-lg-9  " >
                <span class="ml-3 text-light  font-poppins">Total Sales</span>
              <br />
              <span class="font-weight-bold pl-4 text-light">£{{ totalSales }}</span>
                </div>

            </v-row>
            <!-- <div class="d-flex justify-content-between align-items-center">
              <v-icon large class="text-light">mdi-shopping</v-icon>

            <div class=" ml-1 d-flex">
             
            </div>
            
            </div> -->
          </v-card-title>
        </v-card>
      </div>
      <div class="col-lg-3  col-sm-12 mb-3" >
        <v-card :style="{ height: '150px'}" class="bg-primary"> 
          <v-card-title>

            <v-row> 
              <div class="col-lg-2  " >
                <v-icon large class="text-light">mdi-cart</v-icon>

              </div>
              <div class="col-lg-9  " >
                <span class="ml-3 text-light  font-poppins">Total Orders</span>
              <br />
              <span class="font-weight-bold pl-4 text-light">{{ totalOrders }}</span>
                </div>

            </v-row>


            <!-- <div class="d-flex justify-content-between align-items-center">
              <v-icon large class="text-light">mdi-cart</v-icon>
              <span class="ml-3  text-light  font-poppins">Total Orders</span>
              <span class="font-weight-bold pl-4 text-light">{{ totalOrders }}</span>
            </div> -->
          </v-card-title>
        </v-card>
      </div>
      <div class="col-lg-3  col-sm-12 mb-3" >
        <v-card :style="{ height: '150px', backgroundColor: '#1D2939' }"> 
          <v-card-title>
            <v-row> 
              <div class="col-lg-2  " >
                <v-icon large class="text-light">mdi-domain</v-icon>

              </div>
              <div class="col-lg-9  " >
                <span class="ml-3 text-light  font-poppins">Registered Businesses</span>
              <br />
              <span class="font-weight-bold pl-4 text-light">{{ totalBusinesses }}</span>
                </div>

            </v-row>
            <!-- <div class="d-flex justify-content-between align-items-center">
              <v-icon large class="text-light">mdi-domain</v-icon>
              <span class="ml-3 text-light  font-poppins ">Registered Businesses</span>
              <span class="font-weight-bold pl-4 text-light">{{ totalBusinesses }}</span>
            </div> -->
          </v-card-title>
        </v-card>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-8 col-sm-12 mb-3">
        <apexchart type="line" height="500" :options="updatedChartOptions" :series="series"></apexchart>
      </div>
      <div class="col-lg-4 col-sm-12 mb-3">
        <apexchart type="pie" height="500" :options="pieChartOptions" :series="pieChartData"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueApexCharts from 'vue-apexcharts';
import ApiService from "@/core/services/api.service";

export default {
  name: "dashboard",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      totalCustomers: 0,
      totalSales: 0,
      totalOrders: 0,
      totalBusinesses: 0,
      series: [{
        name: 'Sales',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 500,
          type: 'line',
          zoom: {
            enabled: false
          },
          // foreColor: '#9B92DD'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
           colors: ['#5D5BA0']
        },
        title: {
          text: 'Sales Over the Last 12 Months',
          align: 'left',
          style: {
             color: '#5D5BA0',
             fontFamily: 'Poppins, sans-serif !important',
             fontWeight:'bolder'

          }
        },
        xaxis: {
          categories: [], // Placeholder for month names
          tickAmount: 10,
          labels: {
            style: {
              // colors: '#9B92DD'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              color: '#5D5BA0',
             fontFamily: 'Poppins, sans-serif !important',
             fontWeight:'bolder'            }
          }
        }
      },
      pieChartData: [],
      pieChartOptions: {
        labels: ['Delivery', 'Collection'],
         colors: ['#5D5BA0', '#03B397'],
        legend: {
          show: true,
          position: 'bottom',
          labels: {
             colors: ['#5D5BA0', '#03B397']
          }
        }
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Admin Dashboard" }]);
    this.getDashboardData();
  },
  computed: {
    updatedChartOptions() {
      return {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: this.chartOptions.xaxis.categories // Update categories here
        },
      };
    },
  },
  methods: {
    async getDashboardData() {
      try {
        const response = await ApiService.get('admin/dashboard'); // Adjust endpoint as per your API setup
        const data = response.data;

        // Update card data
        this.totalCustomers = data.totalUsers;
        this.totalSales = data.totalEarnings;
        this.totalOrders = data.totalOrders;
        this.totalBusinesses = data.totalBusinesses;

        // Update sales chart data
        this.series = [{
          name: 'Sales',
          data: data.salesChartData
        }];

        // Update x-axis categories with month names
        this.chartOptions.xaxis.categories = data.salesChartCategories;

        // Update pie chart data
        this.pieChartData = [data.orderTypeData.deliveryCount, data.orderTypeData.collectionCount];

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      event.target.classList.add("active");
      return parseInt(event.target.getAttribute("data-tab"));
    },
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-xl-6, .col-xl-8, .col-xl-4, .col-xl-3 {
  flex: 1;
  margin: 1rem;
}
.ml-3 {
  margin-left: 1rem;
}
.text-indego{
  color: #9B92DD !important;
}
.card-custom {
  box-shadow: 50px 40px px rgba(0, 0, 0, 0.1) !important; /* Soft shadow effect */
}

</style> 
